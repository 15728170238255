export default {
  state: {
    optionList: [
      1, 2, 3
    ]
  },
  mutations: {

  },
  actions: {

  },
  modules: {

  }
}
